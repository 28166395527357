import request from '@/utils/request'

// 查询我的设计列表
export function listDesign(query) {
  return request({
    url: '/server/design/list',
    method: 'get',
    params: query
  })
}

// 查询我的设计详细
export function getDesign(designId) {
  return request({
    url: '/server/design/getInfo/' + designId,
    method: 'get'
  })
}

// 新增我的设计
export function addUserDesign(data) {
  return request({
    url: '/server/design/v2/add',
    method: 'post',
    data: data
  })
}
// 新增我的设计
export function addPicDesign(data) {
  return request({
    url: '/server/design/v2/addPicDesign',
    method: 'post',
    data: data
  })
}

export function addDesign(data) {
  return request({
    url: '/server/design/add',
    method: 'post',
    data: data
  })
}

// 修改我的设计
export function updateDesign(data) {
  return request({
    url: '/server/design/edit',
    method: 'post',
    data: data
  })
}



// 删除我的设计
export function delDesign(designId) {
  return request({
    url: '/server/design/delete/' + designId,
    method: 'POST'
  })
}

export function renderDesign(data) {
  return request({
    url: 'https://test.onewinner.cn/api/v1/products/rendering/multiple',
    method: 'POST',
    data: data
  })
}

// 查询我的设计列表
export function getCartInfo(designId) {
  return request({
    url: '/server/design/getSku/' + designId,
    method: 'get',
  })
}

// 设置组合商品 组合我的设计
export function addGroup(data) {
  return request({
    url: '/server/design/addGroup',
    method: 'post',
    data: data
  })
}

// 设置组合商品 组合我的设计
export function cancelGroup(data) {
  return request({
    url: '/server/design/cancelGroup',
    method: 'post',
    data: data
  })
}
// 查询组合设计列表
export function listGroup(data) {
  return request({
    url: '/server/design/listGroup',
    method: 'get',
    params: data
  })
}
export function addBatch(data) {
  return request({
    url: '/server/design/addBatch',
    method: 'post',
    data: data
  })
}
// 设为母体 组合设计
export function setGroupMain(data) {
  return request({
    url: '/server/design/setGroupMain',
    method: 'post',
    data: data
  })
}
// 查询我的优选列表
export function listYx(query) {
  return request({
    url: '/server/design/listYx',
    method: 'get',
    params: query
  })
}

// 新增我的设计
export function addUserDesignNew(data) {
  return request({
    url: '/server/design/v3/add',
    method: 'post',
    data: data
  })
}

// 查询我的优选列表
export function addDesignCopy(data) {
  return request({
    url: '/server/design/copy',
    method: 'post',
    data: data
  })
}

// 查询我的优选列表
export function cleaCache(data) {
  return request({
    url: '/server/design/cleaCache/' + data,
    method: 'post',
  })
}

// 查询我的优选列表
export function pushDesign(data) {
  return request({
    url: '/server/design/push/' + data,
    method: 'post',
  })
}

// 获取第三方数据接口详情
export function getUserInfo(data) {
  return request({
    url: '/system/user/getInfo/' + data,
    method: 'GET',
  })
}