import request from '@/utils/request'

// 查询店铺管理列表
export function getShopList(data) {
  return request({
      url: '/server/shop/select',
      method: 'get',
      params: data
  })
}

// 查询店铺管理列表
export function getShopData(data) {
  return request({
      url: '/server/shop/list',
      method: 'get',
      params: data
  })
}


// 获取店铺管理详细信息
export function getShopInfo(data) {
  return request({
      url: '/server/shop/getInfo/'+data.shopId,
      method: 'get',
      params: data
  })
} 

// 新增店铺管理
export function addShop(data) {
  return request({
      url: '/server/shop/add',
      method: 'post',
      data: data
  });
}

// 店铺添加子账号
export function addSubAccount(data) {
  return request({
      url: '/server/shop/addRela',
      method: 'post',
      data: data
  });
}

// 修改店铺（启用、禁用）
export function updateShop(data) {
  return request({
      url: '/server/shop/edit',
      method: 'post',
      data: data
  });
}

// 店铺授权
export function giveShopAuth(data) {
  return request({
      url: '/server/shop/auth',
      method: 'post',
      data: data
  });
}
// 店铺授权
export function getAuthorizationUrl(data) {
  return request({
      url: '/server/shop/lazada/getAuthorizationUrl',
      method: 'get',
      params: data
  });
}

// 店铺授权
export function getAuthorizationUrlShopee(data) {
  return request({
      url: '/server/shop/oauthUrl',
      method: 'get',
      params: data
  });
}

// 店铺授权
export function getOAuthUrl(params) {
  return request({
      url: '/product/tiktokApi/getOAuthUrl',
      method: 'get',
      params: params
  });
}

// 设置标签
export function setPdf(data) {
  return request({
      url: '/server/shop/setPdf',
      method: 'post',
      data: data
  });
}

// 查询标签
export function getPdf(params) {
  return request({
      url: '/server/shop/getPdf',
      method: 'get',
      params: params
  });
}