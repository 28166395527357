<template>
  <div class="sidebar">
    <div class="oper">
      <div class="title">常用操作</div>
      <div v-if="user.userType == '01'" class="menu-list">
        <div
          @click="handleJump('/authorization/shop/shopee')"
          class="menu-item"
        >
          <div class="menu-icon"><img :src="menu1" /></div>
          <div class="menu-text">Temu店铺</div>
        </div>
        <div
          @click="handleJump('/authorization/shop/lazada')"
          class="menu-item"
        >
          <div class="menu-icon"><img :src="menu2" /></div>
          <div class="menu-text">Shein店铺</div>
        </div>
        <div
          @click="handleJump('/authorization/shop/tiktok')"
          class="menu-item"
        >
          <div class="menu-icon"><img :src="menu3" /></div>
          <div class="menu-text">Tiktok店铺</div>
        </div>
        <div
          @click="handleJump('/order/myOrder/orderShopee')"
          class="menu-item"
        >
          <div class="menu-icon"><img :src="menu4" /></div>
          <div class="menu-text">Temu订单</div>
        </div>
        <div
          @click="handleJump('/order/myOrder/orderLazada')"
          class="menu-item"
        >
          <div class="menu-icon"><img :src="menu5" /></div>
          <div class="menu-text">Shein订单</div>
        </div>
        <div
          @click="handleJump('/order/myOrder/orderTiktok')"
          class="menu-item"
        >
          <div class="menu-icon"><img :src="menu6" /></div>
          <div class="menu-text">Tiktok订单</div>
        </div>
        <div @click="handleJump('/system/account/recharge')" class="menu-item">
          <div class="menu-icon"><img :src="menu7" /></div>
          <div class="menu-text">账户充值</div>
        </div>
        <div @click="handleJump('/select/selection/design')" class="menu-item">
          <div class="menu-icon"><img :src="menu8" /></div>
          <div class="menu-text">我的设计</div>
        </div>
        <div @click="handleJump('/system/account/auth')" class="menu-item">
          <div class="menu-icon"><img :src="menu9" /></div>
          <div class="menu-text">实名认证</div>
        </div>
      </div>
      <div v-if="user.userType == '02'" class="menu-list">
        <div
          @click="handleJump('/carry/shopee/remitDataShopee')"
          class="menu-item"
        >
          <div class="menu-icon"><img :src="menu4" /></div>
          <div class="menu-text">Shopee发布</div>
        </div>
        <div
          @click="handleJump('/carry/lazada/remitDataLazada')"
          class="menu-item"
        >
          <div class="menu-icon"><img :src="menu5" /></div>
          <div class="menu-text">Lazada发布</div>
        </div>
        <div
          @click="handleJump('/carry/tiktok/remitDataTiktok')"
          class="menu-item"
        >
          <div class="menu-icon"><img :src="menu6" /></div>
          <div class="menu-text">TikTok发布</div>
        </div>
        <div @click="handleJump('/map/map_mng/imageMe')" class="menu-item">
          <div class="menu-icon"><img :src="menu10" /></div>
          <div class="menu-text">我的图库</div>
        </div>
        <div @click="handleJump('/select/selection/design')" class="menu-item">
          <div class="menu-icon"><img :src="menu8" /></div>
          <div class="menu-text">我的设计</div>
        </div>
        <div @click="handleJump('/system/account/recharge')" class="menu-item">
          <div class="menu-icon"><img :src="menu11" /></div>
          <div class="menu-text">账号充值</div>
        </div>
      </div>
    </div>
    <div class="sevice">
      <img :src="onlineService" />
    </div>
    <div class="last-login">
      <div class="title">最近登录</div>
      <div class="login-text">
        <img :src="time" />
        登录时间：{{ user.loginDate }}
      </div>
      <div class="login-text"><img :src="ip" />登录IP：{{ user.loginIp }}</div>
      <div class="login-text">
        <img :src="area" />登录地点：{{ user.location }}
      </div>
      <div class="login-text" style="padding: 0 0 0 90px">
        <a @click="handleShowLocation" href="javascript:void(0)">查看更多</a
        ><br />
        <a v-if="false" @click="handleJumpIM()" href="javascript:void(0)"
          >客服系统</a
        >
      </div>
    </div>
    <div class="email">
      <div class="title">投诉邮箱</div>
      <div class="email-text">
        <img :src="email" /><a :href="`mailto:${mail}`">{{ mail }}</a>
      </div>
    </div>
    <div class="questions">
      <div class="title">
        <div class="fleft">常见问题</div>
        <div class="fright">
          <a @click="handleJump('/system/advisory/helpful')" class="more"
            >更多</a
          >
        </div>
      </div>
      <div class="item-list">
        <div v-for="(record, index) in issues" :key="index" class="item">
          <a style="cursor: pointer" @click="handleShowDetail(record)">{{
            record.questionTitle
          }}</a>
        </div>
      </div>
      <div class="submit-order">
        <el-button
          style="cursor: pointer"
          @click="handleJump('/system/advisory/advisory')"
          >问题没解决？提交工单</el-button
        >
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      title="登录历史"
      :visible.sync="showLocation"
      width="1000px"
    >
      <div>
        <el-table :data="locations" border>
          <el-table-column
            label="设备"
            prop="equip"
            align="center"
          ></el-table-column>
          <el-table-column
            label="登录时间"
            prop="loginTime"
            align="center"
          ></el-table-column>
          <el-table-column
            label="登录IP"
            prop="ipAddr"
            align="center"
          ></el-table-column>
          <el-table-column
            label="登录区域"
            prop="location"
            align="center"
          ></el-table-column>
          <el-table-column
            label="登录状态"
            prop="msg"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showLocation = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import menu1 from "@/assets/images/workspace/um1.png"
import menu2 from "@/assets/images/workspace/um2.png"
import menu3 from "@/assets/images/workspace/um3.png"
import menu4 from "@/assets/images/workspace/um4.png"
import menu5 from "@/assets/images/workspace/um5.png"
import menu6 from "@/assets/images/workspace/um6.png"
import menu7 from "@/assets/images/workspace/um7.png"
import menu8 from "@/assets/images/workspace/um8.png"
import menu9 from "@/assets/images/workspace/um9.png"
import menu10 from "@/assets/images/workspace/um10.png"
import menu11 from "@/assets/images/workspace/um11.png"

import onlineService from "@/assets/images/index/online-service.png"
import time from "@/assets/images/index/time.png"
import ip from "@/assets/images/index/ip.png"
import area from "@/assets/images/index/area.png"
import email from "@/assets/images/index/email.png"
import { getHelpList } from "@/api/sysSupport/help"
import { getLastLoginInfo } from "@/api/sysSupport/question"
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      menu1: menu1,
      menu2: menu2,
      menu3: menu3,
      menu4: menu4,
      menu5: menu5,
      menu6: menu6,
      menu7: menu7,
      menu8: menu8,
      menu9: menu9,
      menu10: menu10,
      menu11: menu11,

      onlineService: onlineService,
      time: time,
      ip: ip,
      area: area,
      email: email,
      issues: [],
      showLocation: false,
      locations: [],
    }
  },
  computed: {
    ...mapGetters(["dictData", "dictMap", "nginx", "user"]),
    mail() {
      return this.$store.state.setting.siteData.email
    },
  },
  watch: {
    showLocation() {
      if (this.showLocation) {
        this.getLastLoginInfo()
      }
    },
  },
  methods: {
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },
    async getHelpList() {
      let result = await getHelpList()
      this.issues = result.rows
    },
    async getLastLoginInfo() {
      let result = await getLastLoginInfo()
      this.locations = result.data
    },
    /**详情 */
    handleShowDetail(row) {
      this.$router.push({
        path: "/system/advisory/help_detail",
        query: { questionId: row.questionId },
      })
    },
    handleShowLocation() {
      this.showLocation = true
    },
    handleJumpIM() {
      let url =
        "https://test-im.onecust.com/#/chat/home?token=" +
        localStorage.getItem("token")
      window.open(url, "_blank")
    },
  },
  mounted() {
    this.getHelpList()
    this.getLastLoginInfo()
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 240px;
  float: left;
  margin-right: 16px;
  font-size: 12px;
  margin-top: 50px;
}
.oper {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
  box-sizing: border-box;
  background: #ffffff;
  overflow: hidden;
}
.title {
  position: relative;
  padding: 8px 16px 0 16px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin: 0 0 8px 0;
  overflow: hidden;
}
.menu-list {
  margin: 24px 0 0 0;
  overflow: hidden;
  .menu-item {
    float: left;
    width: 77px;
    text-align: center;
    font-size: 12px;
    padding: 0 0 16px 0;
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
    }
    .menu-text {
      margin: 8px 0 0 0;
      color: #aaa;
    }
  }
  .menu-item :hover {
    img {
      opacity: 0.8;
    }
  }
}
.sevice {
  margin: 16px 0 0 0;
  img {
    width: 240px;
  }
}
.last-login {
  margin: 16px 0 0 0;
  padding: 0 0 8px 0;
  background: #ffffff;
  .login-text {
    line-height: 30px;
    padding: 0 0 0 16px;
  }

  img {
    width: 12px;
    float: left;
    margin: 9px 6px 0 0;
  }
}
.email {
  margin: 16px 0 0 0;
  padding: 0 0 8px 0;
  background: #ffffff;
  .email-text {
    line-height: 30px;
    padding: 0 0 0 16px;
  }
  img {
    width: 12px;
    float: left;
    margin: 9px 6px 0 0;
  }
}
.questions {
  margin: 16px 0 8px 0;
  padding: 0 0 16px 0;
  background: #ffffff;
  .submit-order {
    text-align: center;
    margin-top: 8px;
  }
}
.fleft {
  float: left;
}
.fright {
  float: right;
}
.item-list {
  padding: 0 0 0 16px;
  .item {
    line-height: 28px;
  }
}
a,
a:visited {
  color: #4934b2;
}
.more {
  font-weight: normal;
  font-size: 12px;
  color: #333333;
  cursor: pointer;
}
</style>
