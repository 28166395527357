<template>
  <!-- -->
  <div class="main">
    <div class="todo">
      <div
        style="overflow: hidden; margin: 1px 0 5px 0; line-height: 32px"
        class="title"
      >
        <div style="float: left;display:flex;">
          <div>订单跟踪</div>
          <el-carousel style="width:700px;margin-left: 10px;" height="30px" direction="vertical">
            <el-carousel-item v-for="(item,index) in msgList" :key="index">
              <a style="display: block;color:red;width: 700px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" href="/system/todolist/hint">{{ item.content }}</a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div
          v-if="false"
          style="float: left; margin: -3px 0 0 calc(50% - 300px)"
        >
          订单数：<span class="order-num">{{ orderStat.orderNum }}</span>
          <span class="fba-num"
            >（FBA <span class="order-num">{{ orderStat.fbaOrderNum }}</span
            >）</span
          >
        </div>
        <div v-if="false" style="float: left; margin: -3px 0 0 31px">
          订单件数：<span class="order-num">{{ orderStat.proNum }}</span>
          <span class="fba-num"
            >（FBA <span class="order-num">{{ orderStat.fbaProNum }}</span
            >）</span
          >
        </div>
        <div style="float: right; margin: -2px 16px 0 0">
          <el-radio-group size="mini" v-model="dateRange">
            <el-radio-button label="1">今天</el-radio-button>
            <el-radio-button label="2">昨天</el-radio-button>
            <el-radio-button label="3">本周</el-radio-button>
            <el-radio-button label="4">本月</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div style="padding: 0 0 0 16px"></div>
      <div class="todo-content" style="padding-top: 0">
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-icon"><img :src="state1" /></div>
          <div class="todo-item-title">未付款</div>
          <div class="todo-item-num">{{ orderStat.waitPayNum }}</div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-icon"><img :src="state2" /></div>
          <div class="todo-item-title">已付款</div>
          <div class="todo-item-num">{{ orderStat.payNum }}</div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-icon"><img :src="state3" /></div>
          <div class="todo-item-title">排单中</div>
          <div class="todo-item-num">{{ orderStat.waitDoNum }}</div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-icon"><img :src="state4" /></div>
          <div class="todo-item-title">生产中</div>
          <div class="todo-item-num">{{ orderStat.doNum }}</div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-icon"><img :src="state5" /></div>
          <div class="todo-item-title">已发货</div>
          <div class="todo-item-num">{{ orderStat.sendNum }}</div>
        </div>
      </div>
    </div>

    <div class="stats">
      <div class="title" style="margin: 0px 0 12px 2px">数据统计</div>
      <div
        class="todo-content"
        style="padding-top: 0; padding-bottom: 0; margin: 0 0 16px 0"
      >
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-title">
            <img style="margin: 0 5px 0 0" :src="sm1" />今日营业额（元）
          </div>
          <div class="todo-item-num">
            {{ stat.todaySaleActPay }}
          </div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-title"><img :src="sm2" />本周营业额（元）</div>
          <div class="todo-item-num">
            {{ stat.weekSaleActPay }}
          </div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-title"><img :src="sm3" />今日付款（元）</div>
          <div class="todo-item-num">
            {{ stat.todayProActPay }}
          </div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-title"><img :src="sm4" />当月充值（元）</div>
          <div class="todo-item-num">
            {{ stat.rechargeAmount }}
          </div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-title"><img :src="sm5" />设计次数</div>
          <div class="todo-item-num">{{ stat.designNum }}</div>
        </div>
      </div>
      <div class="todo-content" style="padding-top: 0">
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-title"><img :src="sm6" />昨日营业额（元）</div>
          <div class="todo-item-num">
            {{ stat.yestSaleActPay }}
          </div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-title"><img :src="sm7" />本月营业额（元）</div>
          <div class="todo-item-num">
            {{ stat.monthSaleActPay }}
          </div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-title"><img :src="sm8" />昨天付款（元）</div>
          <div class="todo-item-num">
            {{ stat.yestProActPay }}
          </div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-title"><img :src="sm9" />当前余额（元）</div>
          <div class="todo-item-num">
            {{ stat.balance }}
          </div>
        </div>
        <div
          onmouseover="setBackground(this,'#e6f5fe')"
          onmouseout="setBackground(this,'#ffffff')"
          class="todo-item"
        >
          <div class="todo-item-title"><img :src="sm10" />总图片量</div>
          <div class="todo-item-num">{{ stat.imgNum }}</div>
        </div>
      </div>
    </div>
    <div v-if="false" class="warn">
      上传图片或/和文字时不得存在涉政、涉黄、侵权等违法行为，情节严重的将予以封号处理，感谢您的配合。
    </div>
    <div class="banner-wrap">
      <div class="swiper-wrapper">
        <a
          data-href="https://www.hicustom.com/pages/xinpin210706"
          class="swiper-img banner banner1"
        ></a>
      </div>
      <div class="swiper-wrapper" style="margin: 0px">
        <a
          data-href="https://www.hicustom.com/pages/xinpin210706"
          class="swiper-img banner banner2"
        ></a>
      </div>
    </div>
    <div class="notice">
      <div style="flex: 1; margin: 0 20px 0 0">
        <div class="title" style="margin: 0 0 8px 0">收入统计</div>
        <div class="revenue">
          <div class="content">
            <div style="flex: 1; font-size: 14px; text-align: center">
              <el-radio-group
                size="mini"
                v-model="selection"
                style="margin: 0 0 32px 0"
              >
                <el-radio-button label="1">今日交易</el-radio-button>
                <el-radio-button label="2">昨日交易</el-radio-button>
              </el-radio-group>
              <div style="text-align: left">
                <div>成交金额（元）</div>
                <div
                  style="
                    font-size: 30px;
                    padding: 21px 0 29px 0;
                    border-bottom: 1px solid #ddd;
                    margin: 0 8px 8px 0;
                  "
                >
                  {{ incomeStat.transAct }}
                </div>
                <div style="display: flex; margin: 36px 0 8px 0; color: #aaa">
                  <div style="flex: 1">退款金额（元）</div>
                  <div style="flex: 1">退款笔数（笔）</div>
                </div>
                <div style="display: flex; color: #333">
                  <div style="flex: 1">{{ incomeStat.refAct }}</div>
                  <div style="flex: 1">{{ incomeStat.refNum }}</div>
                </div>
              </div>
            </div>
            <div style="flex: 1.7; margin: 5px 0 0 0">
              <div id="main" :style="{ width: '100%', height: '302px' }"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="system-notice" style="position: relative; flex: 1; margin: 0">
        <div class="title" style="margin: 0 0 8px 0">系统公告</div>
        <div class="content">
          <el-table :data="tableData" @row-click="noticeClick">
            <el-table-column
              show-overflow-tooltip
              prop="noticeTitle"
              label="公告标题"
            >
              <template slot-scope="scope">
                <div style="cursor: pointer">
                  <span
                    v-if="scope.row.isTop == '1'"
                    class="white"
                    style="
                      display: inline-block;
                      background: red;
                      border-radius: 16px;
                      border: 1px solid red;
                      width: 36px;
                      cursor: pointer;
                      line-height: 19px;
                      font-size: 12px;
                      text-align: center;
                    "
                    >{{ scope.row.isTop == "1" ? "置顶" : "" }}</span
                  >
                  {{ scope.row.noticeTitle }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="noticeType"
              label="公告类型"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                {{ dictMap["notice_type"][scope.row.noticeType] }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="发布时间"
              width="200"
              align="center"
            ></el-table-column>
          </el-table>
          <div class="pager">
            <el-pagination
              @size-change="loadNoticeData"
              @current-change="loadNoticeData"
              :current-page.sync="page.pageNum"
              :page-size="page.pageSize"
              layout="prev, pager, next"
              :total="page.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="product-menu">
      <div class="tabs_style">
        <div
          @click="handleClickHotProduct"
          :class="{ active: productMenu == '9' }"
          class="tab_style"
        >
          热卖推荐
        </div>
        <div
          @click="handleClickNewProduct"
          :class="{ active: productMenu == '1' }"
          class="tab_style"
        >
          新品速递
        </div>
      </div>
    </div>
    <div class="product-detail">
      <el-carousel
        class="carousel"
        trigger="click"
        :interval="intervalue"
        arrow="never"
        style="height: 390px"
      >
        <el-carousel-item
          class="carousel-item"
          v-for="(bean, index) in goodsData"
          :key="index"
        >
          <template v-for="(item, i) in bean">
            <div
              @mouseover="handleMouseOver(item)"
              @mouseout="handleMouseOut(item)"
              @click="handleJumpProduct(item)"
              class="product"
            >
              <div class="image" :style="{ height: imageHeight }">
                <img
                  class="product-image evenflow_scale"
                  slot="reference"
                  style="width: 100%"
                  :style="{ width: '100%', height: imageHeight }"
                  :src="
                    `${item.picUrl}?x-oss-process=image/resize,m_fill,h_300,w_300`
                  "
                />
              </div>
              <div class="detail">
                <div :title="item.proName" class="product-title text-ellipsis">
                  {{ item.proName }}
                </div>
                <div
                  class="text"
                  style="
                    border-bottom: 1px solid #ebeef5;
                    line-height: 25px;
                    padding: 0 0 5px 0;
                  "
                >
                  <span class="price">
                    <span class="unit">￥</span
                    >{{ item.skuList[0].price }}</span
                  ><span class="date">{{
                    dictMap["prod_days"][item.prodDays]
                  }}</span>
                </div>
                <div class="text" style="line-height: 33px">
                  平均发货时效：{{ item.avgDelivery }}
                </div>
              </div>
            </div>
          </template>
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      class="notice-dialog"
      title="平台定制公告"
      :visible.sync="noticeDialogVisible"
      width="1000px"
    >
      <div style="height: 550px">
        <div
          style="
            height: 40px;
            line-height: 40px;
            color: #434649;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
          "
        >
          {{ noticeDialogData.title }}
        </div>
        <div style="color: #7d8289; font-size: 13px; text-align: center">
          <span style="margin: 0 16px 0 0"
            >类型：{{
              dictMap["notice_type"][noticeDialogData.noticeType]
            }}</span
          ><span>发布时间：{{ noticeDialogData.createTime }}</span>
        </div>
        <div
          style="
            margin: 20px 10px;
            height: 400px;
            overflow: auto;
            line-height: 24px;
            font-size: 16px;
          "
        >
          <span v-html="noticeDialogData.content"></span>
        </div>
        <div style="margin: 0px 10px; line-height: 24px; font-size: 16px">
          <a
            v-if="noticeDialogData.attachUrl"
            target="_blank"
            :href="noticeDialogData.attachUrl"
            >附件下载</a
          >
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="noticeDialogVisible = false"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import stats1 from "@/assets/images/index/stats1.png"
import stats2 from "@/assets/images/index/stats2.png"
import stats3 from "@/assets/images/index/stats3.png"
import stats4 from "@/assets/images/index/stats4.png"
import state1 from "@/assets/images/user/st1.png"
import state2 from "@/assets/images/user/st2.png"
import state3 from "@/assets/images/user/st3.png"
import state4 from "@/assets/images/user/st4.png"
import state5 from "@/assets/images/user/st5.png"

import sm1 from "@/assets/images/user/sm1.png"
import sm2 from "@/assets/images/user/sm2.png"
import sm3 from "@/assets/images/user/sm3.png"
import sm4 from "@/assets/images/user/sm4.png"
import sm5 from "@/assets/images/user/sm5.png"
import sm6 from "@/assets/images/user/sm6.png"
import sm7 from "@/assets/images/user/sm7.png"
import sm8 from "@/assets/images/user/sm8.png"
import sm9 from "@/assets/images/user/sm9.png"
import sm10 from "@/assets/images/user/sm10.png"

import { listNotice } from "@/api/system/notice"
import hot from "@/assets/images/index/hot.png"
import newImage from "@/assets/images/index/new.png"
import { mapGetters } from "vuex"
import {
  listNew,
  listSale,
  getOverview,
  getOrderNumWithDay,
  getIncomeStat,
  getLast7Days,
} from "@/api/customProduct/product"
import { getUserProfile } from "@/api/system/user"

import { getInfo } from "@/api/login"
import { getShopList } from "@/api/system/shopManage"

import {
  loadTypeData,
  getTypeInfo,
  getTypeTree,
  addType,
  updateType,
  deleteType,
  getTypeTreeList,
} from "@/api/sysSupport/category"

// 引入 ECharts 主模块
var echarts = require("echarts/lib/echarts")
// 引入柱状图
require("echarts/lib/chart/bar")
// 引入提示框和标题组件
require("echarts/lib/component/tooltip")
require("echarts/lib/component/title")
import { listRecharge } from "@/api/business/fundManage"
import { listWithdraw } from "@/api/business/fundManage"
import { getMsgList } from "@/api/system/msg"

window.setBackground = function(dom, color) {
  dom.style.backgroundColor = color
}

export default {
  data() {
    return {
      stats1: stats1,
      stats2: stats2,
      stats3: stats3,
      stats4: stats4,
      state1: state1,
      state2: state2,
      state3: state3,
      state4: state4,
      state5: state5,
      sm1: sm1,
      sm2: sm2,
      sm3: sm3,
      sm4: sm4,
      sm5: sm5,
      sm6: sm6,
      sm7: sm7,
      sm8: sm8,
      sm9: sm9,
      sm10: sm10,
      productMenu: "9",
      hot: hot,
      newImage: newImage,
      tableData: [],
      noticeName: "first",
      productName: "first",
      noticeDialogData: {
        title: null,
        createTime: null,
        content: null,
        noticeType: null,
      },
      dateRange: "1",
      selection: "1",
      noticeDialogVisible: false,
      hotGoods: [
        [
          {
            prodTitle: "百变头巾",
            price: 5.98,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/S3ERDP-1-30-1-1000-2482c250c850be424903b7c835d85b1c.jpg",
          },
          {
            prodTitle: "抱枕套",
            price: 6.68,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/PB93HR-1-30-1-1000-ea5d178a752bc7f9ed8eaacbf7a675a8.jpg",
          },
          {
            prodTitle: "PVC编织餐垫",
            price: 9.72,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/MAKB6P-1-29-1-1000-7040247304756f561ae8fe93ce4beb3b.jpg",
          },
          {
            prodTitle: "挂毯",
            price: 35.58,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/PXTDDO-1-29-1-1000-4106416dde5fff850459a85cd4661c6a.jpg",
          },
          {
            prodTitle: "IPAD皮套壳",
            price: 26.86,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/2OM7D2-2-163-1-1000-6fc2cc073242e13e3e2cb5b8c676e8c8.jpg",
          },
          {
            prodTitle: "方形三合一数据线",
            price: 22.91,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/JYSVU2-9-30-1-200-dacede865bcc7602a836c76b57e90418.jpg",
          },
        ],
        [
          {
            prodTitle: "毛毯盖毯（横）",
            price: 19.84,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet2/MVU7MU-1-29-1-1000-1b055116789bee166c4ac7dd00aa2a6a.jpg",
          },
          {
            prodTitle: "拼图500片（竖板）",
            price: 18.69,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/IQ3IEF-1-30-1-1000-b4117bad684c5489cb585fb6e7d3b4bd.jpg",
          },
          {
            prodTitle: "拼图500片（横板）",
            price: 18.69,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/YZEK2X-2-30-1-1000-7efca32956c8a8f1b2c25cc953e7a5be.jpg",
          },
          {
            prodTitle: "咖啡杯",
            price: 17.4,
            picUrl:
              "https://nimg5.hicustom.com/static/product/LVUXWE-1200-1-29-873fcff2a8bb7d5d23eaa1fb69ac85a9.jpg",
          },
          {
            prodTitle: "咖啡杯",
            price: 16.04,
            picUrl:
              "https://nimg5.hicustom.com/static/product/9JGD55-1200-1-29-d185418f1793533c1f93f1aeb2e8b2a2.jpg",
          },
          {
            prodTitle: "圣诞树裙（加厚款）",
            price: 17.68,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet2/VPMFI3-10-29-1-1000-61aa555dc567c497abcdea1687c19aa0.jpg",
          },
        ],
        [
          {
            prodTitle: "遮阳罩（加硬款）",
            price: 21.41,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/PTN8UW-1-29-1-1000-8b2c10d8b00664a6bb9c5964f5f7e6ad.jpg",
          },
          {
            prodTitle: "羊羔毯",
            price: 46.39,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/XE45E3-1-29-1-1000-41eaca048ef564007f462d641f1f191b.jpg",
          },
          {
            prodTitle: "折叠胸前包",
            price: 23.39,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/NBJNSN-1-29-1-1000-5f06f7ebb3de2ab2415f34420c5f81fc.jpg",
          },
          {
            prodTitle: "防水布料信箱套",
            price: 16.74,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet2/P9WWGO-6-29-1-1000-d1b74e402920f589356bd9749f902974.jpg",
          },
          {
            prodTitle: "手提斜挎餐包",
            price: 29.49,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet2/7MBXWP-1-29-1-1000-70e29a5cdd195a28ecc9e9ddf671801f.jpg",
          },
          {
            prodTitle: "圆形三合一数据线",
            price: 27.17,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/RD9R6F-8-30-1-1000-0cfaae5409fa904840651cb59f2d17c4.jpg",
          },
        ],
        [
          {
            prodTitle: "无框装饰画",
            price: 34.43,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet2/69F3IQ-3-30-1-1000-0e984697835da1a4b89b304b0d356a8b.jpg",
          },
          {
            prodTitle: "加绒棒球服(双面)",
            price: 42.52,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/FA5DYL-3-29-1-1000-e4ed66db1c6656b699421a0786ebf3e2.jpg",
          },
          {
            prodTitle: "男款带帽无口袋短袖卫衣",
            price: 35.9,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/B7BIRT-1-29-1-1000-36edb15e16e8a5c1e0a4f989efaaa252.jpg",
          },
        ],
      ],
      newGoods: [
        [
          {
            prodTitle: "火影文具袋",
            price: 15.0,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/28CEKL-1-29-1-500-2a546e3701b294223db90ea2f212dc06.jpg",
          },
          {
            prodTitle: "束口背包",
            price: 18.52,
            picUrl:
              "https://nimg5.hicustom.com/static/product/TDZXII-500-1-30-19a34b23379f12dac2bd4430fbc01f69.jpg",
          },
          {
            prodTitle: "双肩背包",
            price: 60.5,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/2A688C-1-29-1-500-2a1fa0230a676d290bdc58f2e436cb36.jpg",
          },
          {
            prodTitle: "汽车反向伞",
            price: 25.98,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/Q4RKXR-1-29-1-500-843d90fc357119fa2db346d8953f9971.jpg",
          },
          {
            prodTitle: "皮革行李牌",
            price: 5.68,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/HG22CS-1-29-1-500-fed59218c569dbdea072f006960df089.jpg",
          },
          {
            prodTitle: "防护帽",
            price: 13.7,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/4LV429-6-29-1-500-a97cd13d6fa4e30347878bfc935f446b.jpg",
          },
        ],
        [
          {
            prodTitle: "防尘口罩（多个滤片装）",
            price: 18.17,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/OWZ7ZS-1-166-1-500-31bb5679d1d1d6271ffb7cf353e80b25.jpg",
          },
          {
            prodTitle: "儿童装饰防尘口罩（多个装）",
            price: 6.51,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/GLQ7FK-1-29-1-500-c207f927bf2747e29906c1949e130ebf.jpg",
          },
          {
            prodTitle: "门帘34*56（两片拼接）",
            price: 29.75,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/3VAATK-1-29-1-500-d231531df3b46b26b97e03f5d0e6bc3f.jpg",
          },
          {
            prodTitle: "门帘34*56（两片一致）",
            price: 29.75,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/D8LFLZ-1-29-1-500-ef0786eea674bec00538cd9d35747582.jpg",
          },
          {
            prodTitle: "瑜伽砖",
            price: 13.91,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/NUAB66-1-101-1-500-36d50435f0a9a0e9befe514b3a8295ca.jpg",
          },
          {
            prodTitle: "PVC编织餐垫",
            price: 9.74,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/MAKB6P-1-29-1-500-4f42566af768b181722bdab53330547e.jpg",
          },
        ],
        [
          {
            prodTitle: "单肩防震手提电脑包",
            price: 32.98,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/2YUBM6-1-29-1-500-c07475222e3be61082162711595be3ec.jpg",
          },
          {
            prodTitle: "挂毯90*60inch（竖版）",
            price: 41.28,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/EVM52T-1-29-1-500-8c7f21236a1d65bc252b9a91d20cb2bb.jpg",
          },
          {
            prodTitle: "挂毯90*60inch（横版）",
            price: 41.28,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/T5MF25-1-29-1-500-26fb4c09f631a493304fd18c4108d002.jpg",
          },
          {
            prodTitle: "圆形药盒",
            price: 9.82,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/GYI27R-1-29-1-500-886a8bcb9824822440a78b857789d78d.jpg",
          },
          {
            prodTitle: "超大号宠物垫",
            price: 45.52,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/CM555S-1-29-1-500-84ee1d6228eb5abb0224bc10d6484a4a.jpg",
          },
          {
            prodTitle: "万圣节全副帽子",
            price: 13.98,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/O33AKD-1-29-1-500-3d6b95a7faa31d5089726e6cde0c3380.jpg",
          },
        ],
        [
          {
            prodTitle: "露营折叠凳",
            price: 49.68,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/LE7LCY-1-29-1-500-9195c77527db7f41098c2f182e56c5a3.jpg",
          },
          {
            prodTitle: "方形毛巾",
            price: 15.98,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/IQ4Y46-1-29-1-500-436d7275e7604994d10f549e1be1d394.jpg",
          },
        ],
      ],
      elHot: { background: "red" },
      elNew: { background: "rgb(255,255,255,0)" },
      intervalue: 7000,
      goodsData: [],
      checkList: [],
      imageHeight: "200px",
      stat: {},
      orderStat: {},
      dateRange: "1",
      isMouted: false,
      page: {
        status: "0",
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      incomeStat: { refAct: 0.0, transAct: 0.0, refNum: 0, transNum: 0 },
      userExt: {},
      msgList: [],
    }
  },
  computed: {
    ...mapGetters(["dictData", "dictMap", "nginx", "user"]),
  },
  watch: {
    dateRange() {
      this.getOrderNumWithDay(this.dateRange)
    },
    user: {
      handler() {
        this.getStat()
      },
      deep: true,
    },
    selection() {
      this.getIncomeStat()
    },
  },
  methods: {
    // 获取公告数据
    loadNoticeData() {
      listNotice(this.page)
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.rows
            if (res.rows.length > 0) {
              for (let record of res.rows) {
                if (record.isTop == "0") {
                  this.noticeDialogData.noticeId = record.noticeId
                  this.noticeDialogData.title = record.noticeTitle
                  this.noticeDialogData.content = record.content
                  this.noticeDialogData.noticeType = record.noticeType
                  this.noticeDialogData.createTime = record.createTime
                  this.noticeDialogData.attachUrl = record.attachUrl
                  this.showNotice()
                  break
                }
              }
            }
            this.page.total = res.total
          }
        })
        .catch()
    },
    showNotice() {
      let noticeList = localStorage.getItem("noticeList")
      if (!noticeList) {
        this.noticeDialogVisible = true
        localStorage.setItem(
          "noticeList",
          JSON.stringify([this.noticeDialogData.noticeId])
        )
      } else {
        noticeList = JSON.parse(noticeList)
        if (!noticeList.includes(this.noticeDialogData.noticeId)) {
          this.noticeDialogVisible = true
          noticeList.push(this.noticeDialogData.noticeId)
          localStorage.setItem("noticeList", JSON.stringify(noticeList))
        }
      }
    },
    async drawChart() {
      let data = await getLast7Days({ type: this.type })
      let stat = data.data
      if (this.userExt.userName == "d1") {
        stat = {
          transAct: [
            "8678",
            "9865",
            "10045",
            "12892",
            "15034",
            "15876",
            "18876",
          ],
          refNum: ["0", "0", "0", "0", "0", "0", "0"],
          transNum: ["0", "0", "0", "0", "1", "1", "2"],
          refAct: ["0", "0", "0", "0", "0", "0", "0"],
          time: ["04-28", "04-29", "04-30", "05-01", "05-02", "05-03", "05-04"],
        }
      }
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("main"))
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["成交金额", "成交笔数", "退款金额"],
        },
        xAxis: {
          show: true,
          data: stat.time,
        },
        yAxis: {
          show: false,
        },
        series: [
          {
            name: "成交金额",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: stat.transAct,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 4,
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#8071c9", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#a5c1f3", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false,
                  },
                },
              },
            },
          },
          {
            name: "成交笔数",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: stat.transNum,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 4,
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#8071c9", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#a5c1f3", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false,
                  },
                },
              },
            },
          },
          {
            name: "退款金额",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: stat.refAct,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 4,
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#8071c9", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#a5c1f3", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false,
                  },
                },
              },
            },
          },
        ],
        color: ["#8071c9"],
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    },
    noticeClick(row, col, e) {
      this.noticeDialogData.title = row.noticeTitle
      this.noticeDialogData.content = row.content
      this.noticeDialogData.createTime = row.createTime
      this.noticeDialogData.attachUrl = row.attachUrl
      this.noticeDialogVisible = true
    },
    convert(type) {
      let typeName = "未知"
      switch (type) {
        case "1":
          typeName = "通知"
          break
        case "2":
          typeName = "公告"
          break
        default:
          break
      }
      return typeName
    },
    async getListNew() {
      this.elNew.background = "red"
      this.elNew.color = "white"
      this.elHot.background = ""
      this.elHot.color = ""
      this.choosed = "1"
      let result = await listNew({ pageNum: 1, pageSize: 10 })
      let newGoods = []
      let array = []
      for (let record of result.rows) {
        array.push(record)
        for (let image of record.mainList) {
          if (image.isMain == "2") {
            record.picUrlHover = image.picUrl
          }

          if (image.isMain == "1" && !record.picUrlBak) {
            record.picUrl = image.picUrl
            record.picUrlBak = image.picUrl
          }
        }
        if (array.length == 6) {
          newGoods.push(this._.clone(array))
          array = []
        }
      }
      if (array.length > 0) {
        newGoods.push(array)
      }
      this.goodsData = newGoods
      this.fixProduct()
    },
    async getListSale() {
      this.elHot.background = "red"
      this.elHot.color = "white"
      this.elNew.background = ""
      this.elNew.color = ""
      this.choosed = "0"
      let result = await listSale({ pageNum: 1, pageSize: 10 })
      let array = []
      let goods = []
      for (let record of result.rows) {
        array.push(record)
        for (let image of record.mainList) {
          if (image.isMain == "2") {
            record.picUrlHover = image.picUrl
          }

          if (image.isMain == "1" && !record.picUrlBak) {
            record.picUrl = image.picUrl
            record.picUrlBak = image.picUrl
          }
        }
        if (array.length == 6) {
          goods.push(this._.clone(array))
          array = []
        }
      }
      if (array.length > 0) {
        goods.push(array)
      }
      this.goodsData = goods
      this.fixProduct()
    },
    fixProduct() {
      this.$nextTick(() => {
        let dom = document.querySelector(
          ".carousel-item .product .product-image"
        )
        if (dom) {
          this.imageHeight =
            document.querySelector(".product-image").width + "px"
        }
      })
    },
    handleClickHotProduct() {
      this.productMenu = 9
      this.getListSale()
    },
    handleClickNewProduct() {
      this.productMenu = 1
      this.getListNew()
    },
    async getOrderNumWithDay(type) {
      let orderStat = await getOrderNumWithDay({ type })
      this.orderStat = orderStat.data
      if (this.userExt.userName == "d1") {
        this.orderStat = {
          waitPayNum: 4876,
          proNum: 2,
          payNum: 6990,
          waitDoNum: 3678,
          orderNum: 2,
          fbaOrderNum: 0,
          sendNum: 1108,
          doNum: 2877,
          fbaProNum: 0,
        }
      }
    },
    async getIncomeStat() {
      let incomeStat = await getIncomeStat({ type: this.selection })
      this.incomeStat = incomeStat.data
      if (this.userExt.userName == "d1") {
        this.incomeStat = {
          refAct: 560,
          transAct: 18876,
          refNum: 25,
          transNum: 0,
        }
      }
    },
    async getStat() {
      if (this.user.userType && this.user.userType !== "00" && !this.isMouted) {
        getOverview().then((result) => {
          this.stat = result.data
          if (this.userExt.userName == "d1") {
            this.stat.todaySaleActPay = "9256.20"
            this.stat.weekSaleActPay = "47948.30"
            this.stat.todayProActPay = "10530"
            this.stat.rechargeAmount = "60000"
            this.stat.designNum = "2546"
            this.stat.yestSaleActPay = "11211.38"
            this.stat.monthSaleActPay = "11211.38"
            this.stat.yestProActPay = "9969"
            this.stat.balance = "14153.08"
            this.stat.imgNum = "136789"
          }
        })
        this.isMouted = true
      }
    },
    handleJumpProduct(row) {
      let routeUrl = this.$router.resolve({
        path: "/product",
        query: { pId: row.pId },
      })
      window.open(routeUrl.href, "_blank")
    },
    handleMouseOver(record) {
      if (record.picUrlHover) {
        record.picUrl = record.picUrlHover
      }
    },
    handleMouseOut(record) {
      record.picUrl = record.picUrlBak
    },
    async getHint() {
      let changeResult = await listRecharge({ checkStatus: "01" })
      if (changeResult.total > 0) {
        this.msgError(`您有${changeResult.total}笔充值申请需要处理`)
      }
      let withdrawResult = await listWithdraw({ checkStatus: "01" })
      if (withdrawResult.total > 0) {
        this.msgError(`您有${withdrawResult.total}笔提现申请需要处理`)
      }
    },
    async getInfo() {  //商家端页面
      let result = await getInfo()
      if (result.isExpire == "0") {
        this.$confirm(
          `<span style="color:red;">你的会员已过期，目前已使用店铺${result.bindCnt}个，当前套餐允许使用店铺数${result.maxCnt}个，需要续费${result.payCnt}个，请前往续费套餐或删除多余店铺，谢谢支持！</span>`,
          "提示",
          {
            confirmButtonText: "续费套餐",
            cancelButtonText: "处理店铺",
            dangerouslyUseHTMLString: true,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            closeOnHashChange: false,
            showClose: false,
          }
        )
          .then((type) => {
            if (type == "confirm") {
              this.$router.push({
                path: "/system/account/purchaseMember",
              })
            }
          })
          .catch(() => {
            this.$router.push({
              path: "/authorization/shop/lazada",
            })
          })
      } else {
        if (this.user.userType && this.user.userType !== "00") { //管理端不显示，管理端有时候会访问到index会跳出来
          getShopList().then(res => {
            if (res.code == 200 && res.rows.length>0) {
              const unauthorizedArr = res.rows.filter(shopItem => shopItem.authStatus == '01')
              if (unauthorizedArr.length>0) {
                this.$confirm(
                  `<span style="color:red;">共${res.rows.length}个店铺，${unauthorizedArr.length}个未授权，请及时处理！！</span>`,
                  "提示",
                  {
                    confirmButtonText: "去处理",
                    cancelButtonText: "暂不处理",
                    dangerouslyUseHTMLString: true,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    closeOnHashChange: false,
                    showClose: false,
                  }
                )
                .then((type) => {
                  if (type == "confirm") {
                    this.$router.push({
                      path: "/authorization/shop/shopee",
                    })
                  }
                })
              }
            }
          })
        }
      }
    },
    async getUserProfile() {
      let result = await getUserProfile()
      this.userExt = result.user
    },
    getMsgList() {
      getMsgList({
        pageNum: 1,
        pageSize: 10,
      }).then(res => {
        this.msgList = res.rows
      })
    }
  },
  async created() {
    await this.getUserProfile()
    this.loadNoticeData()
    this.drawChart()
    this.productMenu = 9
    this.handleClickHotProduct()
    this.getOrderNumWithDay("1")
    this.getStat()
    this.getIncomeStat()
    this.getInfo()
    if (!window.timerHint && this.user.userType == "00") {
      this.getHint()
      window.timerHint = window.setInterval(getHint, 60 * 1000)
    }
    this.getMsgList()
  },
}
</script>

<style lang="scss" scoped>
.main {
  width: calc(100% - 256px);
  float: left;
  font-size: 12px;
  min-width: 900px;
  margin-top: 50px;
}
.todo {
  padding: 10px 8px 0 8px;
}
.title {
  position: relative;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin: 0 0 8px 0;
  overflow: hidden;
}
.todo-content {
  padding: 32px 0px 0 0;
  display: flex;
}

.todo-item {
  flex: 1;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  padding: 12px 12px;
  border-radius: 12px;
  margin: 0 16px 0 0;
  background: #ffffff;
}

.todo-item-title {
  font-size: 14px;
  font-weight: normal;
  color: #8f8f90;
  float: left;
  color: #333;
  margin: 21px 0 0 0;
}

.stats .todo-item {
  height: 96px;
  text-align: left;
  padding: 16px 12px 16px 16px;
  box-sizing: border-box;
}

.stats .todo-item-title {
  float: inherit;
  margin: 0;
  color: #aaa;
  img {
    float: left;
    margin: 2px 5px 0 0;
  }
}
.stats .todo-item-num {
  float: inherit;
}
.todo-item-icon {
  width: 67px;
  height: 67px;
  float: left;
  img {
    width: 85%;
    height: 85%;
  }
}
.todo-item-num {
  margin: 12px 8px 0 0;
  font-size: 24px;
  float: right;
}
.line {
  position: absolute;
  right: 0px;
  border-right: 1px solid #aaaaaa;
  height: 36px;
  top: 20px;
}
.stats {
  margin: 3px 0 0 0;
  padding: 3px 8px 18px 8px;
  margin-top: 14px;
}
.stats-content {
  display: flex;
}
.stats-item {
  flex: 1;
  text-align: left;
  position: relative;
  margin-right: 8px;
  padding: 16px;
  border-radius: 12px;
  .item-title {
    font-size: 14px;
  }
  .item-num {
    margin: 8px 0 0 0;
    .item-num-font {
      font-size: 22px;
    }
  }
  .item-detail {
    margin-top: 8px;
  }
}
.bg1 {
  background: #feefe6;
}
.bg2 {
  background: #edf9f8;
}
.bg3 {
  background: #f2ecfe;
}
.bg4 {
  background: #eef9ed;
}
.mr16 {
  margin-right: 16px;
}
.item-image {
  position: absolute;
  right: 12px;
  top: 16px;
  img {
    width: 65px;
  }
}
.warn {
  background: #fff8e6;
  padding: 8px 8px;
  margin: 0 25px 0 8px;
  border: 1px solid #fee1b1;
  color: #ff8f21;
}
.notice {
  padding: 4px 8px 8px 8px;
  margin-top: 8px;
  ::v-deep thead {
    display: none;
  }
  ::v-deep .cell {
    font-size: 14px;
  }
  ::v-deep td {
    padding: 12px 0;
  }
}
.menu-list {
  overflow: hidden;
}
.product-image {
  cursor: pointer;
}
.product-menu {
  padding: 0 19px 8px 4px;
  overflow: hidden;
  text-align: center;
  .menu-item {
    float: left;
    margin-right: 60px;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    .img1 {
      position: absolute;
      right: -16px;
      height: 16px;
      top: 3px;
    }
    .img2 {
      position: absolute;
      right: -32px;
      height: 12px;
      top: 6px;
    }
  }
  .selected {
    border-bottom: 3px solid #ff8f21;
    color: #ff8f21;
    font-weight: bold;
  }
}
.product-menu > div {
  overflow: hidden;
  margin: 0 auto;
  display: inline-block;
}

.order-num {
  font-size: 20px;
  color: red;
  margin: 0 0 0 0;
}
.fba-num {
  font-size: 14px;
  margin: 0 0 0 8px;
  font-weight: bold;
}

.red {
  color: red;
  font-weight: bold;
}

.product-detail {
  padding: 0 0 16px 8px;
  margin: 0 20px 0 0;
  overflow: hidden;
  display: flex;

  .carousel {
    width: 100%;
    ::v-deep .product:first-child {
      margin: 0 9px 0 0;
    }
    ::v-deep .product:last-child {
      margin: 0 0 0 9px;
    }
    .carousel-item {
      padding: 4px 0px 2px 2px;
    }
    .product {
      width: calc(16.66% - 16px);
      float: left;
      box-sizing: border-box;
      margin: 0 9px 0 9px;
      background: #ffffff;
      border-radius: 20px;
      line-height: 30px;
      overflow: hidden;
      // border: 1px solid transparent;
      img {
        width: calc(16.66% - 16px);
      }

      .unit {
        font-size: 12px;
      }
      .image {
        padding: 0;
      }
      .text {
        overflow: hidden;
      }
      .product-title {
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
      }
      .price {
        float: left;
        font-size: 16px;
        font-weight: bold;
      }
      .date {
        float: right;
      }
    }
    .product:hover {
      cursor: auto;
      color: #000 !important;
      transition: all 0.35s;
    }
    ::v-deep .el-carousel__button {
      width: 7px;
      height: 7px;
      border-radius: 12px;
      background: #8f8f90;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #4934b2;
    }
  }
  ::v-deep .el-carousel__container {
    height: 380px;
  }
}
.pager {
  text-align: center;
  margin: 4px 0 0 0;
}
.notice {
  display: flex;
  margin: 8px 16px 0 0;
  .revenue {
    background: #ffffff;
    padding: 16px 0px 5px 16px;
    height: 280px;
    .top {
    }
    .content {
      display: flex;
      height: 160px;
    }
  }
  .system-notice {
    .content {
      background: #ffffff;
      padding: 16px 8px 9px 8px;
      height: 275px;
    }
  }
}

.menu-item {
  cursor: pointer;
}

.notice ::v-deep .el-table__row {
  font-size: 14px;
}
.tabs_style {
  position: relative;
  width: calc(100% - 16px);
  text-align: center;
}
.tabs_style::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #e6e6e6;
}
.tab_style {
  position: relative;
  display: inline-block;
  width: 165px;
  padding: 8px 0 16px 0;
  font-size: 14px;
  color: #333;
  vertical-align: top;
  cursor: pointer;
  margin-bottom: 0px;
}
.tabs_style .tab_style {
  color: #333333;
}
.tab_style.active {
  color: #4934b2;
}
.tab_style.active::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #4934b2;
  z-index: 2;
}

.tab_style.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  border-style: solid;
  border-width: 1px;
  border-top-color: #4934b2;
  border-left-color: #4934b2;
  border-right-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  background-color: #f5f6f7;
  z-index: 3;
  transform: rotate(45deg);
}
.banner-wrap {
  display: flex;
  margin: 0px 16px 0 0;
  padding: 0 8px 5px 8px;

  .swiper-wrapper {
    height: 129px;
    flex: 1 1 0%;
    margin: 0px 20px 0px 0px;

    a {
      width: 100%;
      height: 129px;
      display: inline-block;
    }
    a {
      background-position: center;
      border-radius: 12px;
    }
  }
}

.notice-dialog ::v-deep .el-dialog__body {
  padding: 0 0 0 8px !important;
}

.white {
  color: #ffffff;
}
.banner {
  cursor: pointer;
}
.banner1 {
  background: url("../../assets/images/user/banner5.png") no-repeat;
  background-size: 100% 100%;
}
.banner2 {
  background: url("../../assets/images/user/banner6.png") no-repeat;
  background-size: 100% 100%;
}
.detail {
  padding: 4px 12px 0 12px;
}
.product:hover {
  cursor: auto;
  color: #000 !important;
  box-shadow: rgb(0 0 0 / 5%) 2px 6px 10px 5px;
  // border: 1px solid #dddddd !important;
}
.evenflow_scale:hover {
  transform: scale(1.05, 1.05);
}
.evenflow_scale {
  transition: all 0.6s ease;
}
</style>
